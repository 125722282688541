import { getKobaAddress, getStakingAddress, getRewardAddress } from 'utils/addressHelpers'
import axios from 'axios';
import { getBlsContractWithoutSigner, getRewardContractWithoutSigner, getStakingContractWithoutSigner } from 'utils/contractHelpers'
import { ethers } from 'ethers';
import { FamousData, NFTData } from '.';
import { simpleRpcProvider } from 'utils/providers'

const nft_famous = [
  {
    name: 'cryptopunk',
    price: 80,
    address: '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb'
  },
  {
    name: 'boredape',
    price: 82,
    address: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d'
  },
  {
    name: 'Mutantape',
    price: 19,
    address: '0x60e4d786628fea6478f785a6d7e704777c86a7c6'
  },
  {
    name: 'azuki',
    price: 15,
    address: '0xed5af388653567af2f388e6224dc7c4b3241c544'
  },
  // {
  //   name: 'www',
  //   price: 50,
  //   address: '0x0c64e67e2f4c155906295d22569c147a108a376d'
  // },
  // {
  //   name: 'digidaigaku',
  //   price: 12,
  //   address: '0xd1258db6ac08eb0e625b75b371c023da478e94a9'
  // },
  // {/////////////////////////   for test /////////////////
  //   name: 'test nft',
  //   price: 30,
  //   address: '0x761135A25bB3b5e4Cad435073735a54B6E624Ea6'
  // },
]

export const fetchNFTsonWallet = async (collection: string, account: string) => {
  let collectionAddress
  if (collection === "koba") collectionAddress = getKobaAddress()

  const nftContract = getBlsContractWithoutSigner()
  const balance = parseInt((await nftContract.balanceOf(account)).toString());

  if (balance === 0)
    return [];

  let imageHash: string
  if (collection === "koba") {
    imageHash = 'https://raijins.mypinata.cloud/ipfs/bafybeif4r3uwn6vwqbeelc5mptkj7hx4c77uiuo42eedozsz7f3m23trqq/'
  }

  let result = [];
  for (let i = 0; i < balance; i ++) {
    const tokenId = parseInt((await nftContract.tokenOfOwnerByIndex(account, i)).toString());
    result.push(tokenId);
  }

  const nfts: NFTData[] = result.map((item: any) => {
    return {
      id: Number(item),
      name: collection,
      image: `${imageHash}${Number(item)}.png`,
    }
  })

  return nfts;
}

export const fetchAllNFTsonWallet = async (account: string) => {
  const nftAddresses = nft_famous.map((item) => item.address)
  let collectionAddresses = [getKobaAddress().toLowerCase()]

  const options = {
    method: 'GET',
    url: `https://deep-index.moralis.io/api/v2/${account}/nft`,
    params: { chain: 'eth', token_addresses: [...nftAddresses, ...collectionAddresses], format: 'decimal', normalizeMetadata: 'false' },
    headers: { accept: 'application/json', 'X-API-Key': 'tKJraevH6KyOqIaGFdmL5aJDUxUcv0l60GWp4A4EXyQ8wSmUIg8Ql08HtLhYrOCr' }
  };

  const result = await axios.request(options)

  const nfts = result.data.result.filter((item: any) => item.token_address.toLowerCase() === collectionAddresses[0])
  let imageHash = 'https://raijins.mypinata.cloud/ipfs/bafybeif4r3uwn6vwqbeelc5mptkj7hx4c77uiuo42eedozsz7f3m23trqq/'
  const ret_koba = nfts.map((item: any) => {
    return {
      id: item.token_id,
      name: item.name,
      image: `${imageHash}${Number(item.token_id)}.png`,
    }
  })

  const result1: FamousData[] = nft_famous.map((famous_nft: any) => {
    const nfts = result.data.result.filter((nft: any) => nft.token_address.toLowerCase() === famous_nft.address.toLowerCase())
    const tokenIds = nfts.map((nft: any) => nft.token_id);
    return {
      name: famous_nft.name,
      address: famous_nft.address,
      price: famous_nft.price,
      amount: nfts.length,
      tokenIds: tokenIds,
    }
  })

  const sortedResult = result1.sort((prev, next) => next.price * next.amount - prev.price * prev.amount)

  return {
    famous: sortedResult[0],
    koba: ret_koba
  }
}

export const fetchNFTsonStaking = async (collection: string, account: string) => {
  let collectionAddress
  if (collection === "koba") collectionAddress = getKobaAddress()

  const stakingContract = getStakingContractWithoutSigner()
  let result = await stakingContract.getStakingInfo(account)

  let imageHash: string
  if (collection === "koba") {
    imageHash = 'https://raijins.mypinata.cloud/ipfs/bafybeif4r3uwn6vwqbeelc5mptkj7hx4c77uiuo42eedozsz7f3m23trqq/'
  }
  const stakedNfts: NFTData[] = await result[0].map((item: any, index: any) => {
    return {
      id: Number(item),
      name: collection,
      image: `${imageHash}${Number(item)}.png`,
      isClaimed: result[1][index],
      pendingReward: Number(ethers.utils.formatUnits(result[2][index].toString(), 18)),
    }
  })

  result = await stakingContract.getETHPerBlock()
  const usePLSPerBlock = Number(ethers.utils.formatUnits(result.toString(), 18))

  return {
    stakedNfts: stakedNfts,
    plsPerBlock: usePLSPerBlock,
  }
}

export const fetchApproveState = async (collection: string, account: string) => {
  let contract
  if (collection === "koba") contract = getBlsContractWithoutSigner()

  const stakingAddress = getStakingAddress()
  const result = await contract.isApprovedForAll(account, stakingAddress)
  return result
}

export const fetchAnonBalance = async (account: string) => {
  const balanceWei = await simpleRpcProvider.getBalance(account);
  const balanceEther = ethers.utils.formatEther(balanceWei);
  return parseFloat(balanceEther).toPrecision(2);
}