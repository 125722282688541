import { BIG_TEN } from 'utils/bigNumber'
import { parseUnits } from 'ethers/lib/utils'

export const INFURA_ID = 'PkSg__OS-7f9zA6VGKy5UDEx1V28aD5-'
// export const RPC_URL = 'https://eth-mainnet.alchemyapi.io/v2/PkSg__OS-7f9zA6VGKy5UDEx1V28aD5-'
// export const RPC_URL = 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
// export const RPC_URL = 'https://pulsechain.publicnode.com'
export const RPC_URL = 'https://sepolia.era.zksync.dev'

export const CHAIN = 324 //369

export const SUBGRAPH_URL = 'https://api.thegraph.com/subgraphs/name/seniorblockchaindev/bae'

export const NFT_IPFS = 'https://newgate.mypinata.cloud/ipfs/Qma3pitoFVPR8AvP2UepPjvn6wLwG6q4KioV1ULQ5sHRcA/'

export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000

export const sobnftAddress = '0x7CF72132de69daCcbc4624b5E0a26e4f6DFDD2BC';
export const stakingSoBAddress = '0x45f13Be1Ad2f5390343C5A62dBfFF364fb839b69';

export enum GAS_PRICE {
  default = '5',
  fast = '6',
  instant = '7',
  testnet = '10',
}

export const GAS_PRICE_GWEI = {
  default: parseUnits(GAS_PRICE.default, 'gwei').toString(),
  fast: parseUnits(GAS_PRICE.fast, 'gwei').toString(),
  instant: parseUnits(GAS_PRICE.instant, 'gwei').toString(),
  testnet: parseUnits(GAS_PRICE.testnet, 'gwei').toString(),
}

export const famosNfts = [
  {
    name: 'cryptopunk',
    price: 80,
    address: 'https://etherscan.io/address/0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb'
  },
  {
    name: 'boredape',
    price: 82,
    address: 'https://etherscan.io/address/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d'
  },
  {
    name: 'Mutantape',
    price: 19,
    address: 'https://etherscan.io/address/0x60e4d786628fea6478f785a6d7e704777c86a7c6'
  },
  {
    name: 'azuki',
    price: 15,
    address: 'https://etherscan.io/address/0xed5af388653567af2f388e6224dc7c4b3241c544'
  },
  {
    name: 'boredapekennel',
    price: 10,
    address: 'https://etherscan.io/address/0xba30e5f9bb24caa003e9f2f0497ad287fdf95623'
  },
  {
    name: 'pudgypenguins',
    price: 6.5,
    address: 'https://etherscan.io/address/0xbd3531da5cf5857e7cfaa92426877b022e612cf8'
  },
  {
    name: 'doodles',
    price: 7.5,
    address: 'https://etherscan.io/address/0x8a90cab2b38dba80c64b7734e58ee1db38b8992e'
  },
  {
    name: 'clonex',
    price: 6,
    address: 'https://etherscan.io/address/0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b'
  },
  {
    name: 'rtfkt',
    price: 12,
    address: 'https://etherscan.io/address/0x348fc118bcc65a92dc033a951af153d14d945312'
  },
  {
    name: 'digidaigaku',
    price: 12,
    address: 'https://etherscan.io/address/0xd1258db6ac08eb0e625b75b371c023da478e94a9'
  },
  {
    name: 'moobbirds',
    price: 8.5,
    address: 'https://etherscan.io/address/0x23581767a106ae21c074b2276d25e5c3e136a68b'
  },
  {
    name: 'youtherealmvp',
    price: 38,
    address: 'https://etherscan.io/address/0x6efc003d3f3658383f06185503340c2cf27a57b6'
  },
  {
    name: 'nouns',
    price: 39,
    address: 'https://etherscan.io/address/0x9c8ff314c9bc7f6e59a9d9225fb22946427edc03'
  },
  {
    name: 'nftrees',
    price: 15,
    address: 'https://etherscan.io/address/0xeaa4c58427c184413b04db47889b28b5c98ebb7b'
  },
  {
    name: 'proofcollective',
    price: 37,
    address: 'https://etherscan.io/address/0x08d7c0242953446436f34b4c78fe9da38c73668d'
  },
]