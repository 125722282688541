import Header from "views/Header";
// import RightImg from "../../assets/static/imgs/how-works-img-right.png";
import Footer from "components/Footer";

const Work = () => {
  return (
    <div className="ns-main-sec">
      <div className="heron-bg">
        <Header />
        <div className="ns-new-container">
          <div className="ns-flex-work">
            {/* Left Side  */}
            <div className="ns-work-left  ">
              <h3 className="textShineBlacka">HOW IT WORKS</h3>
              <div>
                <span>Gameplay :</span>
                <p>
                  In the world of SoB NFT's, staking your NFT isn't just about earning rewards. It is about to start a journey.
                </p>
                <p>
                  By staking your NFT, you generate SoB points and passive $ETH income. 
                  But here's the twist – once you unstake your NFT you will lose your SoB points.Also you can claim $ETH rewards one time.Make sure you stack them and claim.
                </p>
                <p>
                  Do you seize the rewards now or wait to maximize their potential? 
                </p>
                <p>
                  Each NFT grants you a stake in the royalties, offering a share of the profits and volume generated. 
                  It's not just about individual gains – it's about contributing to the collective success of the SoB NFT's ecosystem. 
                </p>
                
               
                <p>
                  The choice is yours, but choose wisely – the future of your SoB NFT's depends on it.
                </p>
              </div>            
            </div>
            {/* Right Side  */}
            <div className="ns-work-right">
              {/* <img src={RightImg} alt="RightImg" /> */}
              
              <div>
                <span>SoB Royalties :</span>
                <p>
                  We have %5 royalty on our collection. 
                </p>
                <p>
                  These royalties are directed to the staking contract constantly. 
                  This continuous flow of royalties helps to sustain the staking rewards for participants.
                </p>
              </div>
              <div>
                <span>SoB Points :</span>
                <p>
                  Stakers not only receive Ethereum rewards but also accumulate SoB Points by staking their NFTs. 
                </p>
                <p>
                  The number of SoB Points earned corresponds to the quantity of NFTs staked by each participant. 
                  These SoB Points serve as the foundation for receiving $SOB Airdrop. 
                </p>
                <p>
                  Essentially, the more NFTs you stake, the greater the accumulation of points, resulting in higher $SOB Airdrop.
                </p>
              </div>
            </div>            
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Work;
