import { ethers } from 'ethers'
import Web3 from 'web3'
import { simpleRpcProvider } from 'utils/providers'

// Addresses
import {
  getKobaAddress,
  getStakingAddress,
  getRewardAddress,
  getMulticallAddress,
  getVotingAddress,
} from 'utils/addressHelpers'

// ABI
import ERC721 from 'config/abis/erc721.json'
import ERC20 from 'config/abis/erc20.json'
// import NFTStaking from 'config/abis/nftStaking.json'
import NFTStaking from 'config/abis/StakingSoB.json'
import MultiCallAbi from 'config/abis/multicall.json'
import BlsAbi from 'config/abis/Sobnft.json'
import Voting from 'config/abis/voting.json'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export function getContractWithWeb3(abi: any, address: string, provider: any) {
  const web3 = new Web3(provider)
  return new web3.eth.Contract(abi, address)
}

export const getErc721Contract = (provider: any, address: string) => {
  return getContractWithWeb3(ERC721, address, provider)
}

export const getBlsContract = (provider: any) => {
  return getContractWithWeb3(BlsAbi, getKobaAddress(), provider)
}

export const getStakingContract = (provider: any) => {
  return getContractWithWeb3(NFTStaking, getStakingAddress(), provider)
}

export const getVotingContract = (provider: any) => {
  return getContractWithWeb3(Voting, getVotingAddress(), provider)
}

export const getVotingContractWithoutSigner = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(Voting, getVotingAddress(), signer) as any
}

export const getBlsContractWithoutSigner = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(BlsAbi, getKobaAddress(), signer) as any
}

export const getRewardContractWithoutSigner = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ERC20, getRewardAddress(), signer) as any
}

export const getStakingContractWithoutSigner = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(NFTStaking, getStakingAddress(), signer) as any
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer) as any
}