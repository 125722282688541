import React, { useState } from "react";
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import NewLogo from "../../assets/static/imgs/bls-logo.png";
import MenuBtn from "../../assets/static/imgs/Menu Button.png";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useDisconnect } from "wagmi";
import useWallet from "lib/wallets/useWallet";

export const color_primary = "#c010f5"

const discord = () => {
  window.open("https://discord.gg/byanon");
};

const twitter = () => {
  window.open("https://twitter.com/A__________by_A");
};

const linkStyle = {
  textDecoration: "none",
};

const Header = () => {
  const [ShowMenus, setShowMenus] = useState(false);
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();
  const { account } = useWallet();

  return (
    <header>
      <div className="ns-new-container">
        <div className="ns-header-sp">
          <div className="ns-myFlex">
            <Link to="/">
              <img src={NewLogo} alt="" className="ns-newlogo" />
            </Link>
            <ul className={ShowMenus ? "ns-mob-hidden ns-active" : "ns-mob-hidden"}>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "textShineBlack" : "normaltext")}
                >
                  HOME
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/about-us"
                  style={({ isActive }) => {
                    return { color: isActive ? color_primary : "#ffffff" };
                  }}
                >
                  ABOUT US
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/how-it-works"
                  className={({ isActive }) => (isActive ? "textShineBlack" : "normaltext")}
                >
                  HOW IT WORKS
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/mint"
                  style={({ isActive }) => {
                    return { color: isActive ? color_primary : "#ffffff" };
                  }}
                >
                  MINT
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/stake"
                  className={({ isActive }) => (isActive ? "textShineBlack" : "normaltext")}
                >
                  STAKE
                </NavLink>
              </li>
              
              <li className="ns-hidden-on-desk">
                {!account ?
                  (
                    <button className="btn" onClick={openConnectModal}>
                      CONNECT
                    </button>
                  ) : (
                    <button className="ns-header-btn-sec" onClick={()=>disconnect()}>
                      {account.slice(0, 3) + "..." + account.slice(39, 42)}
                    </button>
                  )}
                <button className="ns-menus-btn-close" onClick={() => setShowMenus(!ShowMenus)}>
                  ✖
                </button>
              </li>
            </ul>
            {!account ?
              (
                <button className="btn" onClick={openConnectModal}>
                  CONNECT
                </button>
              ) : (
                <button className="ns-header-btn" onClick={()=>disconnect()}>
                  {account.slice(0, 3) + "..." + account.slice(39, 42)}
                </button>
              )}
            <button className="ns-menus-btn" onClick={() => setShowMenus(!ShowMenus)}>
              <img src={MenuBtn}  alt="MenuBtn" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
