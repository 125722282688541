import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import { connectorsForWallets, darkTheme, RainbowKitProvider, Theme, WalletList } from "@rainbow-me/rainbowkit";
import {
  safeWallet,
  injectedWallet,
  metaMaskWallet,
  walletConnectWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { Chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { arbitrum, avalanche } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import merge from "lodash/merge";

const WALLET_CONNECT_PROJECT_ID = "de24cddbaf2a68f027eae30d9bb5df58";
const APP_NAME = "SoB NFT's";

const walletTheme = merge(darkTheme(), {
  colors: {
    modalBackground: "#111722",
    accentColor: "#9da5f2",
    menuItemBackground: "#808aff14",
  },
  radii: {
    modal: "4px",
    menuButton: "4px",
  },
} as Theme);

const zksync = {
  id: 324,
  name: 'zkSync',
  network: 'zkSync',
  //iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
  //iconBackground: '#fff',
  nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://zksync-mainnet.g.alchemy.com/v2/HE8pEqQyFo_ViRwx6xMDTYBxl0248BdY'] },
    public: { http: ['https://zksync-mainnet.g.alchemy.com/v2/HE8pEqQyFo_ViRwx6xMDTYBxl0248BdY'] },
  },
  blockExplorers: {
    default: { name: 'zkSyncScan', url: 'https://explorer.zksync.io' },
  },
  contracts: { // https://www.multicall3.com/deployments
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 88189,
    },
  },
} as const satisfies Chain;

const { chains, provider } = configureChains(
  [zksync/*, arbitrum, avalanche*/],
  [publicProvider()]
);

const recommendedWalletList: WalletList = [
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      safeWallet({ chains }),
      metaMaskWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      walletConnectWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
    ],
  },
];

const othersWalletList: WalletList = [
  {
    groupName: "Others",
    wallets: [
      coinbaseWallet({ appName: APP_NAME, chains }),
    ],
  },
];

const connectors = connectorsForWallets([...recommendedWalletList, ...othersWalletList]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

// @ts-expect-error
export default function WalletProvider({ children }) {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider theme={walletTheme} chains={chains} modalSize="compact">
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
