import { ApolloClient, HttpLink, InMemoryCache, gql } from "@apollo/client";
import { createObjectCsvWriter } from "csv-writer";

const GET_DATA_STAKING = gql(`{
    earnedPoints {
      id
      user
      point
    }
  }`);


export const getStakingData = async () => {
    try {
        // console.log("Getting NFT staking data from Subgraph...");

        const client = new ApolloClient({
            link: new HttpLink({ 
                uri: 'https://api.studio.thegraph.com/query/67901/sobnft/v0.0.5' 
            }),
            cache: new InMemoryCache(),
        });
        // console.log("New apolloClinet...");

        const result = await client.query({
            query: GET_DATA_STAKING,
        });
      
        // console.log("Count of Accounts :", result.data.stakeCumulatives.length);

        return result.data.earnedPoints;
    } catch (error) {
        console.error(error);
    }
}

// export const writeStakeDataToCsvFile = async() => {
//     const stakeCumulative = await getStakingData();
//     try {
//         const csvWriter = createObjectCsvWriter({
//           path: 'outputCumulative.csv',
//           header: [
//             { id: 'id', title: 'id' },
//             { id: 'user', title: 'user' },
//             { id: 'count', title: 'count' }
//           ],
//         });
//         console.log('######');
        
//         csvWriter
//         .writeRecords(stakeCumulative)
//         .then(() => console.log('outputCumulative CSV file created successfully'))
//         .catch((error) => console.error('Error writing to CSV file:', error));
//       } catch (err) {
//         console.log(err)
//         process.exit();
//       }
// }