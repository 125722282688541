// import React from 'react'
// import Header from 'views/Header';

// const discord = () => {
//   window.open("https://discord.gg/byanon");
// }

// const twitter = () => {
//   window.open("https://twitter.com/A__________by_A");
// }

// const Home = () => {

//   return (
//     <div className="allWrap">
//         <div className="light">
//             <Header />
//             <div className="vidCont">
//                 <video className="videoBg" autoPlay loop muted playsInline>
//                     <source src="images/bg.mp4" type="video/mp4" />
//                     Sorry, your browser doesn't support videos.
//                 </video>
//             </div>
//             <div className="announcement">
//                 <p className="line-2 anim-typewriter2">ANNOUNCEMENTS</p>
//                 <p className="announcementP2">Welcome to SoB NFT's, <br /> SoB NFT's Minting is Live. Dont Miss your chance!</p>
//                 <p className="lineP"></p>
//                 <button className="futuristic-button" onClick={() => window.location.href = '/mint'}>MINT</button>
//             </div>
//         </div>

//     </div>
//   )
// }

// export default Home

import BackStoryImg from "../../assets/static/imgs/backstory.png";
import RoadmapImg from "../../assets/static/imgs/Roadmap-new.png";
import HeroArrow from "../../assets/static/imgs/Hero-arrow.png";
import HeroImg from "../../assets/static/imgs/hero-avatar.png";
import Header from "views/Header";
import Footer from "components/Footer";
import { useContractRead } from "wagmi";
import BlsAbi from "config/abis/Sobnft.json";
import { CHAIN, sobnftAddress } from "config";
import { useEffect, useState } from "react";
import ImgArrow from "../../assets/static/imgs/arrow-down.png";

const Home = () => {
  const [maxNftSupply, setMaxNftSupply] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (id: any) => {
    setActiveIndex((prevIndex) => (prevIndex === id ? null : id));
  };


  // const { data: MAX_NFT_SUPPLY } = useContractRead({
  //   address: sobnftAddress,
  //   abi: BlsAbi,
  //   functionName: "MAX_NFT_SUPPLY",
  //   enabled: true,
  //   args: [],
  //   watch: true,
  //   chainId: CHAIN,
  // });
  const MAX_NFT_SUPPLY = 3333;
  
  useEffect(() => {
    setMaxNftSupply(Number(MAX_NFT_SUPPLY));
  }, [MAX_NFT_SUPPLY]);

  const faqs = [
    {
      id: 0,
      question: "When is mint?",
      answer:[
        "TBA in discord and twitter.",
      ],
    },
    {
      id: 1,
      question: "What is total supply?",
      answer:[
        'Our total supply consists of 3333 items.',
      ],
    },
    // {
    //   id: 2,
    //   question: "What is mint price?",
    //   answer:[
    //     "0.007ETH for originallist.",
    //     "0.01ETH for whitelist.",
    //     "0.014ETH for public mint."
    //   ],
    // },
    {
      id: 3,
      question: "Passive income?",
      answer:[
        "Possess a stake in the minting fees and all royalties through staking your NFT.", 
        "Each NFT bestows upon you a share of the minting fees and royalties, enabling you to generate passive income. And also blast points.",
        "Secure rewards by staking your NFT. Rewards stem from 7.5% royalties and 10% of minting fees.",
      ],
    },
    {
      id: 4,
      question: "Staking?",
      answer:[
        "Secure rewards by staking your NFT.",
        "Rewards stem from 7.5% royalties and 10% of minting fees.",
      ],
    },
  ];
  return (
    <div className="ns-main-sec">
      <div className="herona-bg">
        <Header />
        {/* Hero Section  */}
        <div className="ns-new-container">
          <div className="ns-hero-sec">
          
             {/*<div className="ns-hero-right">
              <img src={HeroImg} alt="HeroImg" />
  </div> */}
          </div>
        </div>
      
        {/* End Hero Section  */}
        {/* FAQs  */}


        

            {/* End FAQs  */}
       
      </div>
    </div>
  );
};

export default Home;
