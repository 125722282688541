// export default Leaderboard

import { useEffect, useState } from "react";
import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import Header from "views/Header";
import Footer from "components/Footer";

import BlsAbi from "config/abis/Sobnft.json";
import StakingSoBAbi from "config/abis/StakingSoB.json";
import { CHAIN, sobnftAddress, stakingSoBAddress } from "config";
import { getStakingData } from "utils/subgraphHelper";
import useWallet from "lib/wallets/useWallet";

const Leaderboard = () => {
  const { account } = useWallet();
  const [maxNftSupply, setMaxNftSupply] = useState(0);
  const [totalStakingCount, setTotalStakingCount] = useState(0);
  const [totalMintCount, setTotalMintCount] = useState(0);
  const [rateTotalMint, setRateTotalMint] = useState(0.0);
  const [rateStaking, setRateStaking] = useState(0.0);  

  const [stakedAccountData, setStakedAccountData] = useState<{   
    id: string;
    account: any;
    point: any;
  }[]>([]);
  const emptyStakedAccountData = [{id: "", account: "", point: ""}, {id: "", account: "", point: ""}, {id: "", account: "", point: ""}, {id: "", account: "", point: ""}];
  const [updatableTableData, setUpdatableTableData] = useState(false);

  // const { data: MAX_NFT_SUPPLY } = useContractRead({
  //   address: sobnftAddress,
  //   abi: BlsAbi,
  //   functionName: "MAX_NFT_SUPPLY",
  //   enabled: true,
  //   args: [],
  //   watch: true,
  //   chainId: CHAIN,
  // });
  const MAX_NFT_SUPPLY = 3333;

  const { data: mintCounter } = useContractRead({
    address: sobnftAddress,
    abi: BlsAbi,
    functionName: "mintCounter",
    enabled: true,
    args: [],
    watch: true,
    chainId: CHAIN,
  });

  const { data: cntStakedNFT } = useContractRead({
    address: sobnftAddress,
    abi: BlsAbi,
    functionName: "balanceOf",
    enabled: true,
    args: [stakingSoBAddress],
    watch: true,
    chainId: CHAIN,
  });

  useEffect(() => {
    setTotalMintCount(mintCounter? Number(mintCounter) : 0);
  }, [mintCounter]);

  useEffect(() => {
    setMaxNftSupply(MAX_NFT_SUPPLY? Number(MAX_NFT_SUPPLY) : 0);
  }, [MAX_NFT_SUPPLY]);

  useEffect(() => {
    setTotalStakingCount(cntStakedNFT? Number(cntStakedNFT) : 0);
  }, [cntStakedNFT]);

  useEffect(() => {
    if (maxNftSupply != 0)
      setRateTotalMint((totalMintCount / maxNftSupply) * 100);
  }, [maxNftSupply, totalMintCount]);

  useEffect(() => {
    if (maxNftSupply != 0)
      setRateStaking((totalStakingCount / maxNftSupply) * 100);
  }, [maxNftSupply, totalStakingCount]);
  
  //-> for update table data
  useEffect(() => {    
    getStakingData().then((data) => {
      let stakeInfo = [];

      for (let i = 0; i < data?.length; i++) {
        const account = data[i].id;

        stakeInfo.push({
          id: "0x" + account.slice(2, 6) + "..." + account.slice(36, 42),
          account: account,
          point: Number(ethers.utils.formatUnits(data[i].point.toString(), 18))
        });
      }
      stakeInfo.sort((a,b) => {
        return a.point > b.point ? -1 : 1;
      })
      setStakedAccountData(stakeInfo);
      setUpdatableTableData(true);
    });
  }, []);

  useEffect(() => {    
    if (totalStakingCount < 1) return;
    if (stakedAccountData.length < 1) return;

    let stakeInfo = [];
    stakeInfo = stakedAccountData.map((accountStake) => {
      return {
        id: accountStake.id,
        account: accountStake.account,
        point: accountStake.point,
      };
    })
    
    stakeInfo.sort((a,b) => {
      return a.point > b.point ? -1 : 1;
    })
    
    setStakedAccountData(stakeInfo);
    setUpdatableTableData(false);
  }, [totalStakingCount, updatableTableData]);
  //<- for update table data

  return (
    <div className="ns-main-sec">
      <div
        className="hero-bg"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Header />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3
              className="ns-mint-heading-main"
              style={{ marginBottom: "0rem" }}
            >
              <span>SoB NFT's</span> Leaderboard
            </h3>
            <h4 className="ns-leaderboard-heading-state">
              Total Staked: {totalStakingCount}({rateStaking.toPrecision(2)}%)
            </h4>
            <table className="ns-leaderboard-table">
              <thead className="ns-leaderboard-table-head">
                <tr>
                  <th>POSITION</th>
                  <th>STAKER</th>
                  <th>TOTAL POINTS</th>
                </tr>
              </thead>
              <tbody className="ns-leaderboard-table-body">
                {stakedAccountData.length ?
                  stakedAccountData.map((item, index) => {
                    
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item["id"]}</td>
                        <td>{Number(item["point"]).toFixed(2)}</td>
                      </tr>
                    );
                  }) : (emptyStakedAccountData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item["id"]}</td>
                        <td>{item["point"]}</td>
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
            {/* </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Leaderboard;
