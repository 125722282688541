import Header from "views/Header";
import React, { useEffect, useState } from "react";
import { NFTData } from "state/user";
import picOne from "../../assets/static/imgs/1pic-details.png";
import picTwo from "../../assets/static/imgs/2pic-details.png";
import picThree from "../../assets/static/imgs/3pic-details.png";
import picFour from "../../assets/static/imgs/4pic-details.png";
import picFive from "../../assets/static/imgs/hand-money-svgrepo-com.png";
import stakeImg from "../../assets/stake.gif";
import unStakeImg from "../../assets/unstake.gif";
import nft from "../../assets/icon.png";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { CHAIN, sobnftAddress, stakingSoBAddress } from "config";
import Footer from "components/Footer";
import { getStakingAddress } from 'utils/addressHelpers'
import useWallet from "lib/wallets/useWallet";
import { useContractRead, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction, useBalance } from "wagmi";
import StakingSoB from 'config/abis/StakingSoB.json';
import BlsAbi from "config/abis/Sobnft.json";
import { watch } from "fs";

const Staking = ({ reloadNftData, setReloadNftData }: any) => {
  const [reload, setReload] = useState(false);
  const { provider, account } = useWallet();
  const [nftsOnWallet, setNftsOnWallet] = useState<NFTData[]>([]);
  const [nftsOnStaking, setNftsOnStaking] = useState<NFTData[]>([]);
  const [pendingRewardTotal, setPendingRewardTotal] = useState(0);

  const { data: balanceData, isError, isLoading } = useBalance({
    address: stakingSoBAddress,
    watch: true
  });

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [selectedNFTsonWallet, setSelectedNFTsonWallet] = useState<number[]>([]);
  const [selectedNFTsonStaking, setSelectedNFTsonStaking] = useState<number[]>([]);

  const [approvePendingTx, setApprovePendingTx] = useState(false);
  const [stakePendingTx, setStakePendingTx] = useState(false);
  const [unStakePendingTx, setUnstakePendingTx] = useState(false);
  const [claimPendingTx, setClaimPendingTx] = useState(false);

  const { data: numNFTStaked } = useContractRead({
    address: sobnftAddress,
    abi: BlsAbi,
    functionName: "balanceOf",
    enabled: true,
    args: [stakingSoBAddress],
    watch: true,
    chainId: CHAIN,
  });

  const { data: numNFTonWallet } = useContractRead({
    address: sobnftAddress,
    abi: BlsAbi,
    functionName: "balanceOf",
    enabled: !!account,
    args: [account],
    watch: true,
    chainId: CHAIN,
  });

  const [countNFTonWallet, setCountNFTonWallet] = useState(0);
  useEffect(() => {
    setCountNFTonWallet(numNFTonWallet ? Number(numNFTonWallet) : 0);
  }, [numNFTonWallet])

  const { data: nftWalletInfo } = useContractRead({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "getTokensInWallet",
    enabled: !!account,
    args: [[sobnftAddress], account],
    watch: true,
    chainId: CHAIN,
  });

  const { data: stakePause } = useContractRead({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "stakePause",
    enabled: !!account,
    args: [],
    watch: true,
    chainId: CHAIN,
  });

  const { data: nftStakingInfo } = useContractRead({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "getStakingInfo",
    enabled: !!account,
    args: [account],
    watch: true,
    chainId: CHAIN,
  });

  const { data: totalDistributed } = useContractRead({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "totalDistributed",
    enabled: true,
    args: [],
    chainId: CHAIN,
  })

  const { data: earnedPoint } = useContractRead({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "getEarnedPoint",
    enabled: true,
    args: [account],
    chainId: CHAIN,
  })

  useEffect(() => {
    // @ts-ignore
    const items = nftWalletInfo ? nftWalletInfo.map((item) => {
      // @ts-ignore
      const imageHash = 'https://cyan-glad-armadillo-134.mypinata.cloud/ipfs/QmUPQjgNw9Uy3WtqW5bbTEKDwCTPur6BKPGYziXro2teys/';
      return {
        id: Number(item.token_id),
        name: 'koba',
        image: `${imageHash}${Number(item.token_id.toString())}.png`,
      }
    }) : [];

    setNftsOnWallet(items);
  }, [nftWalletInfo])

  useEffect(() => {
    // @ts-ignore

    let pendingRewardTotal = 0.0;
    // @ts-ignore
    const items = nftStakingInfo ? nftStakingInfo[0].map((item, index) => {
      // @ts-ignore
      const pendingReward = Number(ethers.utils.formatUnits(nftStakingInfo[2][index].toString(), 18));
      pendingRewardTotal += pendingReward;

      const imageHash = 'https://cyan-glad-armadillo-134.mypinata.cloud/ipfs/QmUPQjgNw9Uy3WtqW5bbTEKDwCTPur6BKPGYziXro2teys/';
      return {
        id: Number(item),
        name: 'koba',
        image: `${imageHash}${Number(item)}.png`,
        // @ts-ignore
        isClaimed: nftStakingInfo[1][index],
        // @ts-ignore
        pendingReward: pendingReward,
      }
    }) : [];

    setNftsOnStaking(items);
    setPendingRewardTotal(pendingRewardTotal);
  }, [nftStakingInfo])

  const selectNFTsonWallet = async (id: number) => {
    let selectedItems = selectedNFTsonWallet.slice();
    selectedItems.push(Number(id));
    setSelectedNFTsonWallet(selectedItems);
  };
  const unSelectNFTsonWallet = async (id: number) => {
    let selectedItems = selectedNFTsonWallet.slice();
    const index = selectedItems.indexOf(Number(id));
    selectedItems.splice(index, 1);
    setSelectedNFTsonWallet(selectedItems);
  };

  const selectNFTsonStaking = async (id: number) => {
    let selectedItems = selectedNFTsonStaking.slice();
    selectedItems.push(Number(id));
    setSelectedNFTsonStaking(selectedItems);
  };
  const unSelectNFTsonStaking = async (id: number) => {
    let selectedItems = selectedNFTsonStaking.slice();
    const index = selectedItems.indexOf(id);
    selectedItems.splice(index, 1);
    setSelectedNFTsonStaking(selectedItems);
  };
  const { data: ethPerBlock } = useContractRead({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "getETHPerBlock",
    enabled: true,
    args: [],
    watch: true,
    chainId: CHAIN,
  });

  const [ethPerDay, setEthPerDay] = useState(0);
  useEffect(() => {
    if (ethPerBlock)
      setEthPerDay(Number(ethers.utils.formatUnits((Number(ethPerBlock) * 43200), 18)));
  }, [ethPerBlock])

  const { data: approveState } = useContractRead({
    address: sobnftAddress,
    abi: BlsAbi,
    functionName: "isApprovedForAll",
    enabled: !!account && !!stakingSoBAddress,
    args: [account, stakingSoBAddress],
    watch: true,
    chainId: CHAIN,
  });

  const { chain } = useNetwork()
  //-> For UnStakeBls
  const { config: unstakeBlsConf } = usePrepareContractWrite({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "unStake",
    args: [selectedNFTsonStaking],
    enabled: !!account && chain?.id === CHAIN && !!selectedNFTsonStaking.length
  })
  const { data: unstakeBlsData, writeAsync: unstakeBlsFn } = useContractWrite(
    unstakeBlsConf
  )
  const { status: unstakeBlsStatus } = useWaitForTransaction({
    hash: unstakeBlsData?.hash
  })
  //<- For UnStakeBls
  const handleUnStake = async () => {
    if (!account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }
    if (selectedNFTsonStaking.length <= 0) {
      // setShowModal2(false)
      toast.error("Select the NFTs that you are going to unstake.");
      return;
    }
    setUnstakePendingTx(true);
    // const result = await callUnstakeNFTs(provider, account, "koba", selectedNFTsonStaking);
    await unstakeBlsFn?.()
      .then(() => {
        setReload(!reload);
        setSelectedNFTsonStaking([]);
        setShowModal2(false);
        toast.success("Unstake Success!");
      })
      .catch((error) => {
        toast.error("Something Went Wrong! Please confirm you are in zkSync Mainnet.");
      });

    setUnstakePendingTx(false);
  };

  //-> For Stake
  const { config: stakeBlsConf } = usePrepareContractWrite({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "stake",
    args: [selectedNFTsonWallet],
    enabled: !!account && chain?.id === CHAIN && !!selectedNFTsonWallet.length
  })
  const { data: stakeBlsData, writeAsync: stakeBlsFn } = useContractWrite(
    stakeBlsConf
  )
  const { status: stakeBlsStatus } = useWaitForTransaction({
    hash: stakeBlsData?.hash
  })
  //<- For Stake
  const handleStake = async () => {
    if (!account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }
    if (selectedNFTsonWallet.length <= 0) {
      // setShowModal1(false)
      toast.error("Select the NFTs that you are going to stake.");
      return;
    }
    if (stakePause === true) {
      toast.error("Paused stake.");
      return;
    }
    setStakePendingTx(true);
    // const result = await callStakeNFTs(provider, account, "koba", selectedNFTsonWallet);
    await stakeBlsFn?.()
      .then(() => {
        setReload(!reload);
        setSelectedNFTsonWallet([]);
        setShowModal1(false);
        toast.success("Stake Success!");
      })
      .catch((error) => {
        toast.error("Something Went Wrong! Please confirm you are in zkSync Mainnet.");
      });

    setStakePendingTx(false);
    // if (result) {
    //   setReload(!reload);
    //   // setReloadNftData(!reloadNftData)
    //   setSelectedNFTsonWallet([]);
    //   setShowModal1(false);
    //   toast.success("Stake Success!");
    // } else {
    //   toast.error("Something Went Wrong! Please confirm you are in zkSync Mainnet.");
    // }
  };
  //-> For ClaimRewards
  const { config: claimBlsConf } = usePrepareContractWrite({
    address: stakingSoBAddress,
    abi: StakingSoB,
    functionName: "claimRewardsForTokens",
    args: [selectedNFTsonStaking],
    enabled: !!account && chain?.id === CHAIN && !!selectedNFTsonStaking.length
  })
  const { data: claimBlsData, writeAsync: claimBlsFn } = useContractWrite(
    claimBlsConf
  )
  const { status: claimBlsStatus } = useWaitForTransaction({
    hash: claimBlsData?.hash
  })
  //<- For ClaimRewards
  const handleClaim = async () => {
    if (!account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }

    if (selectedNFTsonStaking.length <= 0) {
      // setShowModal2(false)
      toast.error("Select the NFTs that you are going to claim.");
      return;
    }

    setClaimPendingTx(true);
    // await callClaim(provider, account, "koba", selectedNFTsonStaking);
    await claimBlsFn?.()
      .then(() => {
        setReload(!reload);
        setSelectedNFTsonStaking([]);
        setShowModal2(false);
        toast.success("Claim Success!");
      })
      .catch((error) => {
        toast.error("Something Went Wrong! Please confirm you are in zkSync Mainnet.");
      });

    setClaimPendingTx(false);
    setReload(!reload);
  };

  //-> For Approve
  const { config: setApproveConf } = usePrepareContractWrite({
    address: sobnftAddress,
    abi: BlsAbi,
    functionName: "setApprovalForAll",
    args: [stakingSoBAddress, true],
    enabled: !!account && chain?.id === CHAIN
  })
  const { data: setApproveData, writeAsync: setApproveFn } = useContractWrite(
    setApproveConf
  )
  const { status: ApproveStatus } = useWaitForTransaction({
    hash: setApproveData?.hash
  })
  //<- For Approve
  const handleApprove = async () => {
    if (!account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }
    setApprovePendingTx(true);
    // const result = await callApproveForAll(provider, "koba", account, true, famous);
    await setApproveFn?.()
      .then(() => {
        setReload(!reload);
        toast.success("Approve Success! Now you can stake.");
      })
      .catch((error) => {
        toast.error("Something Went Wrong! Please confirm you are in Ethereum Mainnet.");
      });
    setApprovePendingTx(false);
  };

  return (
    <div className="ns-main-sec">
      <div className="hero-bg">
        <Header />
        <h3 className="ns-mint-heading-main" style={{ marginBottom: "0rem" }}>
          <span>SoB NFT's</span> Stake
        </h3>
        <div className="ns-new-container-stacking">
          <div className="ns-stack-main-wrap">
            <div className="ns-stak-details-container">
              {/* Detail One  */}
              <div className="ns-detail-stake">
                <div className="ns-detail-flex-stake">
                  <div className="ns-details-logo">
                    <img src={picOne} alt="picOne" />
                  </div>
                  <div className="ns-detail-content">
                    <span>{numNFTStaked ? Number(numNFTStaked) : 0}</span>
                    <span>STAKED</span>
                  </div>
                </div>
              </div>
              {/* End Detail One  */}

              {/* Detail TWo  */}
              <div className="ns-detail-stake">
                <div className="ns-detail-flex-stake">
                  <div className="ns-details-logo">
                    <img src={picTwo} alt="picTwo" className="ns-graph-logo" />
                  </div>
                  <div className="ns-detail-content">
                    <span>{ethPerDay.toFixed(8)} ETH/Day</span>
                    <span>REWARDS PER NFT</span>
                  </div>
                </div>
              </div>
              {/* End Detail Two  */}
              <div className="ns-detail-stake">
                <div className="ns-detail-flex-stake">
                  <div className="ns-details-logo">
                    <img src={picFour} alt="picFour" />
                  </div>
                  <div className="ns-detail-content">
                    <span>{pendingRewardTotal.toFixed(8)} ETH</span>
                    <span>PENDING</span>
                  </div>
                </div>
              </div>
              {/*  Detail Three  */}
              <div className="ns-detail-stake">
                <div className="ns-detail-flex-stake">
                  <div className="ns-details-logo">
                    <img src={picThree} alt="picThree" />
                  </div>
                  <div className="ns-detail-content">
                    <span>{earnedPoint ? Number(ethers.utils.formatUnits(earnedPoint.toString(), 18)).toFixed(2) : 0}</span>
                    <span>Earned Point</span>
                  </div>
                </div>
              </div>
              
              
              {/* End Detail Three  */}

              {/* End Detail FOur  */}
             
              {/* End Detail Four */}
            </div>
            <div className="ns-stak-details-containerbottom">

            <div className="ns-detail-stake">
                <div className="ns-detail-flex-stake">
                  <div className="ns-details-logo">
                    <img src={picFive} alt="picFive" className="ns-graph-logo" />
                  </div>
                  <div className="ns-detail-content">
                    <span>{totalDistributed ? Number(ethers.utils.formatUnits(totalDistributed.toString(), 18)) : 0}</span>
                    <span>Total Distributed Point</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Staking Card Sections  */}
            <div className="ns-stack-cards-container">
              {/* Card  */}
              <div className="ns-stack-card">
                <h4>Stake</h4>
                <div className="ns-nft-content">
                  <div style={{ width: "165px", borderRadius: "12px", overflow: "hidden" }}>
                    <img src={stakeImg} alt="StackOne" />
                  </div>
                  <span className="ns-nft-type">SoB NFTs</span>
                  <div className="ns-nft-status">
                    <p>{Number(countNFTonWallet)} Available</p>
                  </div>
                </div>
                <div className="ns-stackbtn-new">
                  <button className="btn" onClick={() => setShowModal1(true)}>
                    Stake
                  </button>
                </div>
              </div>
              {/* End Card  */}
              {/* Card  */}
              <div className="ns-stack-card">
                <h4>Claim / UnStake</h4>
                <div className="ns-nft-content">
                  <div style={{ width: "165px", borderRadius: "12px", overflow: "hidden" }}>
                    <img src={unStakeImg} alt="StackTWo" />
                  </div>
                  <span className="ns-nft-type">SoB NFTs</span>
                  <div className="ns-nft-status">
                    <p>{nftsOnStaking.length} Staked</p>
                  </div>
                </div>
                <div className="ns-stackbtn-new">
                  <button className="btna" onClick={() => setShowModal2(true)}>
                    Unstake
                  </button>
                </div>
              </div>
              {/* End Card  */}
            </div>
            {/* End Staking Card Sections  */}
          </div>
        </div>
        
      </div>

      {/* MOdal Two  */}
      {showModal1 && (
        <div className="ns-stake-overlay">
          <div className="ns-modal-container">
            <div className="ns-modal-heading-flex">
              <div className="ns-modal-heading">
                <h3>My NFTs</h3>
              </div>
              <button onClick={() => setShowModal1(false)}>✖</button>
            </div>
            <div className="ns-modal-body">
              <div className="ns-modal-nft-grid">
                {nftsOnWallet.map((item: any, index: number) => {
                  const selectState =
                    selectedNFTsonWallet.filter((item1: any) => Number(item1) === Number(item.id)).length > 0;
                  return (
                    <div key={index} className="ns-modal-nft-grid-item">
                      <div className={`${selectState ? "ns-stake-modal-card-select" : "ns-stake-modal-card"}`}>
                        <span>SoB#{item.id}</span>
                        {selectState ? (
                          <div onClick={() => unSelectNFTsonWallet(item.id)}>
                            <img src={item.image} alt="nft" style={{ transform: "scale(0.98)" }}
                            />
                          </div>
                        ) : (
                          <div onClick={() => selectNFTsonWallet(item.id)}>
                            <img src={item.image} alt="nft" />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* NFT Content ENd  */}
            </div>
            <div className="ns-modal-btn ">
              {approveState ? (
                <button disabled={stakePendingTx} onClick={handleStake}>
                  {stakePendingTx ? "Process..." : "Stake"}
                </button>
              ) : (
                <button disabled={approvePendingTx} className="btnanclaim" onClick={handleApprove}>
                  {approvePendingTx ? "Process..." : "Approve"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {/* MOdal Two End */}

      {/* MOdal One  */}
      {showModal2 && (
        <div className="ns-stake-overlay">
          <div className="ns-modal-container">
            <div className="ns-modal-heading-flex">
              <div className="ns-modal-heading">
                <h3>My Staked NFTs</h3>
              </div>
              <button onClick={() => setShowModal2(false)}>✖</button>
            </div>
            {/* NFT Content  */}
            <div className="ns-modal-body">
              <div className="ns-modal-nft-grid">
                {nftsOnStaking.map((item: any, index: number) => {
                  const selectState =
                    selectedNFTsonStaking.filter((item1: any) => Number(item1) === Number(item.id)).length > 0;
                  return (
                    <div key={index} className="ns-modal-nft-grid-item">
                      <div className={`${selectState ? "ns-stake-modal-card-select" : "ns-stake-modal-card"}`}>
                        <span>SoB#{item.id}</span>
                        {selectState ? (
                          <div onClick={() => unSelectNFTsonStaking(item.id)}>
                            <img src={item.image} alt="nft" style={{ transform: "scale(0.98)" }}
                            />
                          </div>
                        ) : (
                          <div onClick={() => selectNFTsonStaking(item.id)}>
                            <img src={item.image} alt="nft" />
                          </div>
                        )}
                        <div>
                          <span>{item.pendingReward.toFixed(6)} ETH</span>
                        </div>
                        <span>{!item.isClaimed ? "pending" : "claimed"}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* NFT Content ENd  */}
            </div>
            <div className="ns-modal-btn">
              <button disabled={claimPendingTx} className="btnanclaim" onClick={handleClaim}>
                {claimPendingTx ? "Process..." : "Claim"}
              </button>
              <button disabled={unStakePendingTx} className="btnan" onClick={handleUnStake}>
                {unStakePendingTx ? "Process..." : "UnStake"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Staking;
