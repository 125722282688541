import { Link } from "react-router-dom";
import NewFooterLogo from "../assets/static/imgs/bls-logo.png";
import TwitterXLogo from "../assets/static/imgs/TwitterX.png";
import DiscordLogo from "../assets/static/imgs/Discord New.png";
import TelegramLogo from "../assets/static/imgs/Telegram App.png";

const Footer = () => {
  return (
    <footer>
      <div className="ns-new-container">
        <div className="ns-footer-myFlex">
          <Link to="/" className="ns-footer-logo">
            <img src={NewFooterLogo} alt="NewFooterLogo" />
          </Link>
          <div className="ns-footer-links ns-footer-desk">
            <h4>Features</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/how-it-works">How it works</Link>
              </li>
              {/* <li>
                <Link to="/mint">Mint</Link>
              </li> */}
            </ul>
          </div>
          <div className="ns-footer-links ns-footer-desk">
            <h4>Company</h4>
            <ul>
              <li>
                <Link to="/">SoB NFT's</Link>
              </li>
              <li>
                <Link to="/">Privacy policy</Link>
              </li>
              <li>
                <Link to="/">Terms of Use</Link>
              </li>
            </ul>
          </div>
          <div className="ns-footer-links">
            <h4>Join us</h4>
            <div className="ns-footer-socials">
              <a style={{display:"flex"}}
                href="https://twitter.com/SoB NFT's"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TwitterXLogo} alt="TwitterXLogo" />
              </a>
              <a style={{display:"flex"}}
                href="https://discord.com/invite/6PmYx9Zvrm" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={DiscordLogo} alt="DiscordLogo" />
              </a>
              {/* <a href="https://t.me/PulsePixelCartel" target="_blank" rel="noopener noreferrer">
                <img src={TelegramLogo} alt="TelegramLogo" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      {/* CopyRights  */}
      <div className="ns-copy-right">
        <p>All Rights Reserved. SoB NFT's 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
